import { ActionButton, Flex, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import p from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SAP_CODE_TO_ICON_ID } from '@exchange-frontends/utils';

const SupportedProductIcon = styled.svg`
  width: 22px;
  height: 22px;
  margin-right: 5px;
`;

const ProductElement = (props) => {
  const { code, name, useTooltip } = props;

  if (!SAP_CODE_TO_ICON_ID[code]) {
    return null;
  }

  const ProductIcon = (
    <SupportedProductIcon viewBox="0 0 240 234" data-testid={props['data-testid']}>
      <use xlinkHref={`#${SAP_CODE_TO_ICON_ID[code]}`}></use>
    </SupportedProductIcon>
  );

  if (useTooltip) {
    return (
      <TooltipTrigger key={code}>
        <ActionButton isQuiet marginStart="0">
          {ProductIcon}
        </ActionButton>
        <Tooltip variant="inspect">{name}</Tooltip>
      </TooltipTrigger>
    );
  } else {
    return (
      <Flex key={code} marginY="size-50" alignItems="center">
        {ProductIcon} <span>{name}</span>
      </Flex>
    );
  }
};

ProductElement.propTypes = {
  code: p.string,
  name: p.string,
  useTooltip: p.bool,
};

export default ProductElement;
